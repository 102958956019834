<template>
  <b-modal
    id="modal-order-documents"
    scrollable
    size="lg"
    :cancel-title="$t('COMMON.CANCEL')"
    :closeable="modal.canClose"
    :title="modal.title"
    :cancel-disabled="modal.cancelDisabled"
    :hide-footer="modal.hideFooter"
    :ok-title="modal.okTitle"
    :ok-only="modal.okOnly"
    :ok-variant="modal.okVariant"
    :hide-header-close="!modal.canClose"
    :no-close-on-backdrop="!modal.canClose"
    :no-close-on-esc="!modal.canClose"
    @ok="okClicked"
    @show="onModalShow"
  >
    <b-card-group deck v-if="modal.state === 0">
      <b-card
        style="cursor: pointer"
        :title="$t('TEAMS.PROFORMA_INVOICE')"
        @click="onDocumentSelected(documentTypes.PAYMENT_INVOICE)"
      >
      </b-card>
      <b-card
        style="cursor: pointer"
        :title="$t('TEAMS.ACCEPTANCE_ACT')"
        @click="
          onDocumentSelected(documentTypes.TRANSFER_ACCEPTANCE_CERTIFICATE)
        "
      >
      </b-card>
      <b-card
        style="cursor: pointer"
        :title="$t('TEAMS.INVOICE')"
        @click="onDocumentSelected(documentTypes.INVOICE)"
      >
      </b-card>
    </b-card-group>

    <div
      class="alert alert-secondary alert-elevate"
      role="alert"
      v-if="modal.state === 1"
    >
      <div class="alert-text">
        {{ $t("TEAMS.PLEASE_SELECT_CURRENCY") }}
      </div>
    </div>
    <b-card-group deck v-if="modal.state === 1">
      <b-card
        v-for="currency in availableCurrencies"
        :key="currency.key"
        :title="currencyViews[currency].symbol"
        @click="onCurrencySelected(currency)"
        style="cursor: pointer"
      >
        <b-card-text>
          {{ currencyViews[currency].name }}
        </b-card-text>
      </b-card>
    </b-card-group>

    <b-overlay no-wrap :show="modal.state === 2"> </b-overlay>
  </b-modal>
</template>

<script>
import Application from "@/application";
import { API_REQUEST } from "@/core/services/store/api.module";
import axios from "axios";

export default {
  name: "OrderDocumentModal",
  props: {
    order: {
      type: Object,
    },
    country: {
      type: Object,
    },
  },
  data: function () {
    return {
      documentTypeId: null,
      currencyId: null,
      documentTypes: Application.constants.FINANCIAL_DOCUMENT_TYPES,
      currencies: Application.constants.CURRENCIES,
      modal: {
        canClose: true,
        hideFooter: true,
        cancelDisabled: true,
        state: 0,
        title: null,
        okTitle: null,
        okOnly: false,
        okVariant: "info",
      },

      documentTypeViews: [],
      currencyViews: {},
      availableCurrencies: [],
    };
  },

  created: function () {
    this.currencyViews[Application.constants.CURRENCIES.RUB] = {
      name: this.$t("CURRENCIES.RUBLES"),
      symbol: "₽",
    };

    this.currencyViews[Application.constants.CURRENCIES.EUR] = {
      name: this.$t("CURRENCIES.EURO"),
      symbol: "€",
    };

    this.currencyViews[Application.constants.CURRENCIES.USD] = {
      name: this.$t("CURRENCIES.USD"),
      symbol: "$",
    };
  },

  methods: {
    onDocumentSelected: function (documentTypeId) {
      // TODO: убрать после появления счета-фактуры
      if (documentTypeId == this.documentTypes.INVOICE) {
        this.$bvToast.toast("Sorry, this option is temporary unavailable", {
          title: "Warning",
          variant: "warning",
          autoHideDelay: 5000,
        });
        return;
      }

      this.modal.state = 1;
      this.documentTypeId = documentTypeId;
      this.modal.title = this.$t("TEAMS.SELECT_CURRENCY");
      this.modal.okTitle = this.$t("TEAMS.BACK_TO_DOCUMENT_SELECTION");
      this.modal.hideFooter = false;
      this.modal.okOnly = true;
      this.modal.okVariant = "secondary";

      this.availableCurrencies = [];
      if (this.country.rurAvailable)
        this.availableCurrencies.push(Application.constants.CURRENCIES.RUB);
      if (this.country.eurAvailable)
        this.availableCurrencies.push(Application.constants.CURRENCIES.EUR);
      if (this.country.usdAvailable)
        this.availableCurrencies.push(Application.constants.CURRENCIES.USD);

      // if only one currency available - jump to next step
      if (this.availableCurrencies.length === 1) {
        this.onCurrencySelected(this.availableCurrencies[0]);
        return;
      }
    },

    downloadStoredFile: function (id) {
      window.open(
        axios.defaults.baseURL +
          "/platform/api/file-storage/" +
          id +
          "/download",
        "_blank"
      );
    },

    onCurrencySelected: async function (currencyId) {
      this.currencyId = currencyId;
      this.modal.canClose = false;
      this.modal.state = 2;
      this.modal.hideFooter = true;
      this.modal.title = this.$t("COMMON.PLEASE_WAIT");

      // change target currency id
      if (this.order.targetCurrencyId != currencyId) {
        this.order.targetCurrencyId = currencyId;
        let result0 = await this.$store
          .dispatch(API_REQUEST, {
            resource: "platform/api/order/save",
            requestType: "POST",
            requestParams: {},
            dto: this.order,
          })
          .catch(this.onError);

        if (!result0) {
          this.$bvModal.hide("modal-order-documents");
          return;
        }
      }

      let result1 = await this.$store
        .dispatch(API_REQUEST, {
          resource: "api/financial-document/create",
          requestType: "POST",
          requestParams: {},
          dto: {
            documentTypeId: this.documentTypeId,
            orderId: this.order.id,
          },
        })
        .catch(this.onError);

      if (result1 === undefined) {
        this.$bvModal.hide("modal-order-documents");
        return;
      }

      this.downloadStoredFile(result1.storedFileId);
      this.$bvModal.hide("modal-order-documents");
    },

    onModalShow: function (bvModalEvent) {
      this.onModalInit();
    },

    onModalInit: function () {
      this.documentTypeId = null;
      this.currencyId = null;
      this.modal.state = 0;
      this.modal.hideFooter = true;
      this.modal.canClose = true;
      this.modal.title = this.$t("TEAMS.VIEW_ORDER_DOCS");
      this.modal.okOnly = false;
    },

    okClicked: function (bvModalEvent) {
      if (this.modal.state === 1) {
        this.onModalInit();
        bvModalEvent.preventDefault();
        return;
      }
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
