<template>
  <b-modal
    id="modal-upload-order-documents"
    scrollable
    size="lg"
    :cancel-title="$t('COMMON.CANCEL')"
    :closeable="modal.canClose"
    :title="modal.title"
    :cancel-disabled="modal.cancelDisabled"
    :hide-footer="modal.hideFooter"
    :ok-title="modal.okTitle"
    :ok-only="modal.okOnly"
    :ok-variant="modal.okVariant"
    :hide-header-close="!modal.canClose"
    :no-close-on-backdrop="!modal.canClose"
    :no-close-on-esc="!modal.canClose"
    @show="onModalShow"
  >
    <b-overlay no-wrap :show="modal.state === 0"> </b-overlay>
    <b-form-group
      v-for="doc in incomingDocuments"
      :key="doc.key"
      label-cols-lg="2"
      :label="doc.name"
    >
      <form
        v-if="!doc.storedFileId"
        :id="'storedFileForm-' + doc.documentTypeId"
        enctype="multipart/form-data"
      >
        <b-form-file
          :id="'formFile-' + doc.documentTypeId"
          v-model="doc.file"
          @input="fileChanged(doc)"
          name="content"
          :state="Boolean(doc.storedFileId)"
          :placeholder="$t('COMMON.CHOOSE_FILE_OR_DROP')"
          :drop-placeholder="$t('COMMON.DROP_FILE_HERE')"
          :browse-text="$t('COMMON.BROWSE_FILE')"
        ></b-form-file>
      </form>

      <b-input-group v-else>
        <b-input
          :state="true"
          :value="$t('COMMON.DOCUMENT_UPLOADED')"
        ></b-input>
        <b-input-group-append>
          <b-button
            size="sm"
            variant="primary"
            @click="downloadStoredFile(doc.storedFileId)"
            >{{ $t("COMMON.DOWNLOAD") }}</b-button
          >
          <b-button
            size="sm"
            variant="secondary"
            @click="replaceClicked(doc)"
            >{{ $t("COMMON.REPLACE") }}</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </b-modal>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import axios from "axios";
import Application from "@/application";
import { mapState } from "vuex";

export default {
  name: "UploadOrderDocumentsModal",
  props: {
    order: {
      type: Object,
    },
  },
  data: function () {
    return {
      loadIncomingDocumentsRequest: {
        resource: "/api/financial-document/order-incoming-documents",
        requestType: "GET",
        requestParams: {},
      },

      createFinancialDocumentRequest: {
        resource: "/api/financial-document/create",
        requestType: "POST",
      },

      modal: {
        canClose: true,
        hideFooter: true,
        cancelDisabled: true,
        state: 0,
        title: null,
        okTitle: null,
        okOnly: false,
        okVariant: "info",
      },

      incomingDocuments: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  methods: {
    downloadStoredFile: function (id) {
      window.open(
        axios.defaults.baseURL +
          "/platform/api/file-storage/" +
          id +
          "/download",
        "_blank"
      );
    },

    onModalShow: function (bvModalEvent) {
      this.onModalInit();
      this.loadIncomingDocumentsRequest.requestParams.orderId = this.order.id;
      this.$store
        .dispatch(API_REQUEST, this.loadIncomingDocumentsRequest)
        .then((response) => {
          this.incomingDocuments = response;
          this.modal.state = 1;
        })
        .catch(this.onError);
    },

    replaceClicked: function (documentType) {
      documentType.storedFileId = null;
    },

    fileChanged: async function (documentType) {
      this.modal.state = 0;

      // 1. create file
      let createStoredFile = {
        resource: "/platform/api/file-storage/save",
        requestType: "POST",
        requestParams: {},
        dto: {
          storeModeId: Application.constants.STORE_MODE_TYPES.LOCAL,
          extension: documentType.file.name.split(".").pop(),
          referenceObjectId: this.user.id,
          dataAvailable: true,
        },
      };

      let storedFile = await this.$store
        .dispatch(API_REQUEST, createStoredFile)
        .catch(this.onError);

      if (!storedFile) {
        this.modal.state = 1;
        return;
      }

      let formData = new FormData(
        document.getElementById("storedFileForm-" + documentType.documentTypeId)
      );

      let result = await axios
        .post(
          "/platform/api/file-storage/" + storedFile.id + "/update",
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        )
        .catch(this.onError);

      if (!result) {
        this.modal.state = 1;
        return;
      }

      let dto = {
        orderId: this.order.id,
        documentTypeId: documentType.documentTypeId,
        storedFileId: storedFile.id,
      };

      this.createFinancialDocumentRequest.dto = dto;
      await this.$store
        .dispatch(API_REQUEST, this.createFinancialDocumentRequest)
        .then((response) => {
          documentType.storedFileId = storedFile.id; // mark as loaded
        })
        .catch(this.onError);

      this.modal.state = 1;
    },

    onModalInit: function () {
      this.modal.state = 0;
      this.modal.hideFooter = true;
      this.modal.canClose = true;
      this.modal.title = this.$t("TEAMS.UPLOAD_ORDER_DOCS");
      this.modal.okOnly = false;
      this.incomingDocuments = [];
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
