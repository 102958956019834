<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <div
          v-if="dto.id != null && storedTeamTypeId == null"
          class="alert alert-light alert-elevate"
          role="alert"
        >
          <div class="alert-text">
            <p>
              {{ $t("TEAMS.WELCOME_TO_NATLOGGER") }}
            </p>
          </div>
        </div>
        <b-tabs content-class="mt-3" pills @activate-tab="tabChanged">
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                v-if="
                  dto.id != null && currentTariffInfo.allowLogoCustomization
                "
              >
                <img
                  :src="getLogoUrl"
                  class="float-left team-logo"
                  alt="Logo"
                />
                <b-button-group vertical class="img-button-group" size="sm">
                  <b-button
                    variant="link"
                    class="img-button-change"
                    @click="changeLogo"
                    ><img src="/media/svg/buttons/edit.svg" />
                    {{ $t("TEAMS.LOGO.EDIT") }}</b-button
                  >
                  <b-button
                    v-if="logo.logoChanged"
                    variant="link"
                    class="img-button-undo"
                    @click="undoLogo"
                    ><img src="/media/svg/buttons/undo.svg" />
                    {{ $t("TEAMS.LOGO.UNDO") }}</b-button
                  >
                  <b-button
                    v-if="dto.logo != null && logo.imgDataUrl != null"
                    variant="link"
                    @click="deleteLogo"
                    class="img-button-delete"
                    ><img src="/media/svg/buttons/delete.svg" />
                    {{ $t("TEAMS.LOGO.DELETE") }}</b-button
                  >
                </b-button-group>
              </b-form-group>
              <my-upload
                v-if="logo.show"
                field="image"
                @crop-success="cropSuccess"
                v-model="logo.show"
                :width="100"
                :height="100"
                :langType="getCurrentLang()"
                url=""
                noCircle
                :params="logo.params"
                :headers="logo.headers"
                img-format="png"
              ></my-upload>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME') + ' *'"
                label-for="field-name"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.name"
                  v-bind:disabled="readOnly"
                  :state="validateDtoState('name')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="field-mailing-list"
                label-cols-lg="2"
                :label="$t('TEAMS.MAILING_LIST')"
                label-for="field-mailing-list"
              >
                <div class="tags-input-container">
                  <span
                    v-for="(email, index) in mailingList"
                    :key="index"
                    class="tag"
                  >
                    {{ email }}
                    <span class="remove-tag" @click="removeEmail(index)"
                      >×</span
                    >
                  </span>
                  <b-form-input
                    v-model="newEmail"
                    @keyup.enter="addEmail"
                    :placeholder="$t('TEAMS.ADD_MAIL')"
                  />
                </div>
              </b-form-group>

              <b-form-group
                id="field-team-type"
                label-cols-lg="2"
                :label="$t('COMMON.TYPE')"
                label-for="field-team-type"
              >
                <b-form-select
                  v-model="dto.teamTypeId"
                  :options="teamTypes"
                  v-bind:disabled="storedTeamTypeId != null || readOnly"
                >
                  <template v-slot:first>
                    <option :value="null">
                      {{ $t("TEAMS.SELECT_TEAM_TYPE") }}
                    </option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                id="team-country"
                label-cols-lg="2"
                :label="$t('TEAMS.COUNTRY')"
              >
                <vue-typeahead-bootstrap
                  id="team-country-suggestion"
                  v-model="teamCountryStr"
                  :minMatchingChars="1"
                  trim
                  v-bind:disabled="storedCountryId != null || readOnly"
                  @input="
                    requestSuggestionVariants(
                      'countrySuggestionProcessor',
                      teamCountryStr
                    )
                  "
                  :data="suggestedVariants"
                  :serializer="suggestFormater"
                  @hit="onTeamCountrySelected"
                  :placeholder="$t('TEAMS.TYPE_COUNTRY_NAME')"
                  ref="teamCountryTypeahead"
                >
                </vue-typeahead-bootstrap>
              </b-form-group>

              <b-form-group
                v-if="!readOnly && dto.id != null"
                id="field-tariff"
                label-cols-lg="2"
                :label="$t('TEAMS.TARIFF')"
                label-for="field-tariff"
              >
                <b-input-group>
                  <b-form-input
                    id="field-tariff-input"
                    v-model="dto.tariffName"
                    disabled
                  ></b-form-input>
                  <b-input-group-append v-if="dto.parentTeamId == null">
                    <b-button
                      variant="primary"
                      v-on:click="onShowTariffModal"
                      >{{ $t("TEAMS.CHANGE_TARIFF") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group
                v-if="currentTariffInfo.allowIpFiltrating"
                id="field-ips"
                label-cols-lg="2"
                :label="$t('TEAMS.ALLOWED_ADDRESSES')"
                label-for="field-ips-input"
              >
                <b-form-textarea
                  id="field-ips-input"
                  v-model="dto.allowedIpAddresses"
                ></b-form-textarea>
              </b-form-group>

              <div
                :key="countryKey"
                v-if="
                  dto.teamTypeId == constants.TEAM_TYPES.CORPORATE &&
                  dto.country
                "
              >
                <div class="alert alert-primary alert-elevate" role="alert">
                  <div class="alert-text">
                    {{ $t("TEAMS.TEAM_FIELDS_NOTE") }}
                  </div>
                </div>

                <div
                  v-if="
                    dto.country &&
                    dto.country &&
                    dto.country.rurAvailable === true
                  "
                >
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.COMPANY_NAME") + " *" }}</label>
                        <input
                          type="text"
                          v-model="dto.companyName"
                          :disabled="readOnly"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.COMPANY_NAME')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.companyName.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.TAX_NUMBER") + " *" }}</label>
                        <input
                          v-model="dto.taxNumber"
                          v-bind:disabled="readOnly"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.TAX_NUMBER')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.taxNumber.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.DIRECTOR_NAME") + " *" }}</label>
                        <input
                          type="text"
                          v-model="dto.directorName"
                          :disabled="readOnly"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.DIRECTOR_NAME')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.directorName.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.KPP") + " *" }}</label>
                        <input
                          type="text"
                          v-model="dto.kpp"
                          :disabled="readOnly"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.KPP')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.kpp.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.ADDRESS") + " *" }}</label>
                        <input
                          type="text"
                          v-model="dto.address"
                          :disabled="readOnly"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.ADDRESS')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.address.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.OGRN") + " *" }}</label>
                        <input
                          v-model="dto.ogrn"
                          :disabled="readOnly"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.OGRN')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.ogrn.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.BANK_NAME") + " *" }}</label>
                        <input
                          type="text"
                          v-model="dto.bankName"
                          :disabled="readOnly"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.BANK_NAME')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.bankName.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.RS") + " *" }}</label>
                        <input
                          type="text"
                          v-model="dto.checkingAccount"
                          :disabled="readOnly"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.RS')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.checkingAccount.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.PHONE") + " *" }}</label>
                        <input
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.PHONE')"
                          v-model="dto.phone"
                          v-bind:disabled="readOnly"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.phone.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{
                          $t("TEAMS.CORRESPONDENT_ACCOUNT") + " *"
                        }}</label>
                        <input
                          type="text"
                          v-model="dto.correspondentAccount"
                          :disabled="readOnly"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.CORRESPONDENT_ACCOUNT')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.correspondentAccount.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.EMAIL") + " *" }}</label>
                        <input
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.EMAIL')"
                          v-model="dto.email"
                          v-bind:disabled="readOnly"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.email.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.BIK") + " *" }}</label>
                        <input
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.BIK')"
                          v-model="dto.bik"
                          :disabled="readOnly"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.bik.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.WEB") }}</label>
                        <input
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.WEB')"
                          v-model="dto.web"
                          v-bind:disabled="readOnly"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="
                    dto.country.usdAvailable === true ||
                    dto.country.eurAvailable === true
                  "
                >
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.COMPANY_NAME") + " *" }}</label>
                        <input
                          type="text"
                          v-model="dto.companyName"
                          :disabled="readOnly"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.COMPANY_NAME')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.companyName.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.TAX_NUMBER") + " *" }}</label>
                        <input
                          v-model="dto.taxNumber"
                          v-bind:disabled="readOnly"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.TAX_NUMBER')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.taxNumber.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.ADDRESS") + " *" }}</label>
                        <input
                          v-model="dto.address"
                          :disabled="readOnly"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.ADDRESS')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.address.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.DIRECTOR_NAME") + " *" }}</label>
                        <input
                          v-model="dto.directorName"
                          :disabled="readOnly"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.DIRECTOR_NAME')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.directorName.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.PHONE") + " *" }}</label>
                        <input
                          v-model="dto.phone"
                          :disabled="readOnly"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.PHONE')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.phone.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.EMAIL") + " *" }}</label>
                        <input
                          v-model="dto.email"
                          :disabled="readOnly"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.EMAIL')"
                        />
                        <span
                          class="form-text"
                          style="color: rgb(246, 78, 96)"
                          v-if="!$v.dto.email.required"
                          >{{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <label>{{ $t("TEAMS.WEB") }}</label>
                        <input
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="$t('TEAMS.WEB')"
                          v-model="dto.web"
                          v-bind:disabled="readOnly"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-tab>

          <b-tab :title="$t('TEAMS.MEMBERS')" v-if="!readOnly">
            <b-table
              id="table-parameters"
              striped
              bordered
              :items="dto.members"
              :fields="memberFields"
              selectable
              select-mode="single"
              @row-selected="onTeamRowSelected"
            >
            </b-table>
          </b-tab>

          <b-tab :title="$t('TEAMS.NOTIFICATION_RULES')" v-if="dto.id != null">
            <b-table
              id="table-parameters"
              striped
              bordered
              :items="dto.notificationRules"
              :fields="notificationRuleFields"
              selectable
              select-mode="single"
              @row-selected="onRuleRowSelected"
            >
            </b-table>
          </b-tab>

          <b-tab :title="$t('TEAMS.ORDERS')" v-if="!readOnly && dto.id != null">
            <b-table
              id="table-parameters"
              striped
              bordered
              :items="orders"
              :fields="orderFields"
              selectable
              select-mode="single"
              @row-selected="onOrderRowSelected"
            >
            </b-table>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-cancel-subscription-confirmation"
      :title="$t('TEAMS.ARE_YOU_SURE_YOU_WANT_TO_CANCEL_SUB')"
      :cancel-title="$t('COMMON.CANCEL')"
      @ok="onCancelSubscription"
    >
      <p class="my-4" v-if="selectedOrder">
        {{ selectedOrder.productsAsString }}
      </p>
    </b-modal>

    <b-modal
      id="modal-add-member"
      :title="$t('TEAMS.MEMBER')"
      :cancel-title="$t('COMMON.CANCEL')"
      size="lg"
      @ok="addNewMember"
      @cancel="cancelAddTeamMember"
    >
      <b-card-body>
        <b-form-group
          id="use"
          label-cols-lg="3"
          :label="$t('TEAMS.EXISTED_USER')"
          label-for="field-owner"
          v-if="newMember.id == null"
        >
          <vue-typeahead-bootstrap
            id="user"
            v-model="newMember.userName"
            :minMatchingChars="1"
            trim
            @input="
              requestSuggestionVariants(
                'userSuggestionProcessor',
                newMember.userName
              )
            "
            :data="suggestedVariants"
            :serializer="suggestFormater"
            @hit="onUserSelected"
            :placeholder="$t('TRANSACTIONS.TYPE_USER_NAME')"
            ref="userTypeahead"
          >
          </vue-typeahead-bootstrap>
        </b-form-group>

        <b-form-group
          id="field-or-enter-email"
          label-cols-lg="3"
          :label="$t('TEAMS.OR_ENTER_EMAIL')"
          label-for="field-user-name"
          v-if="newMember.id == null && dto.id != null"
        >
          <b-form-input
            id="field-user-name"
            v-model="newMember.email"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="field-user-name"
          label-cols-lg="3"
          :label="$t('COMMON.USER')"
          label-for="field-user-name"
          v-if="newMember.id != null"
        >
          <b-form-input
            id="field-user-name"
            v-model="newMember.userName"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="field-team-type-2"
          label-cols-lg="3"
          :label="$t('TEAMS.PERMISSION')"
          label-for="field-team-type-2"
        >
          <b-form-select
            v-model="newMember.permissionId"
            :options="permissions"
          >
            <template v-slot:first>
              <option value="" disabled>
                {{ $t("TEAMS.SELECT_PERMISSION") }}
              </option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-card-body>
    </b-modal>

    <b-modal
      id="modal-add-rule"
      :title="$t('TEAMS.NOTIFICATION_RULE')"
      :cancel-title="$t('COMMON.CANCEL')"
      @ok="addNewRule"
      @cancel="cancelRule"
      size="xl"
    >
      <b-card-body>
        <b-form-group
          id="field-notification-rule-type"
          label-cols-lg="2"
          :label="$t('TEAMS.NOTIFICATION_RULE_TYPE')"
          label-for="field-notification-rule-type"
        >
          <b-form-select
            v-model="newRule.notificationRuleTypeId"
            :options="notificationRuleTypes"
          >
            <template v-slot:first>
              <option :value="null" disabled>
                {{ $t("TEAMS.SELECT_NOTIFICATION_RULE_TYPE") }}
              </option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="field-devices"
          label-cols-lg="2"
          :label="$t('MENU.DEVICES')"
          label-for="field-devices"
        >
          <multi-suggestion
            id="deviceSuggestion"
            suggestion-processor="deviceSuggestionProcessor"
            :element-name="$t('DEVICE.DEVICE')"
            :elements-name="$t('DEVICE.DEVICES')"
            :selected-elements="selectedDevices"
            :addErrorNotification="true"
            @on-element-added="onDeviceSelected"
            @on-remove-element="removeDevice"
          ></multi-suggestion>
        </b-form-group>

        <template
          v-if="
            newRule.notificationRuleTypeId ==
            constants.NOTIFICATION_RULE_TYPES.PARAMETER
          "
        >
          <b-form-group
            id="field-parameters"
            label-cols-lg="2"
            :label="$t('MENU.PARAMETERS')"
            label-for="field-parameters"
          >
            <multi-suggestion
              id="parameterSuggestion"
              suggestion-processor="parameterSuggestionProcessor"
              :element-name="$t('PARAMETERS.PARAMETER')"
              :elements-name="$t('PARAMETERS.PARAMETERS')"
              :selected-elements="selectedParameters"
              :addErrorNotification="true"
              @on-element-added="onParameterSelected"
              @on-remove-element="removeParameter"
            ></multi-suggestion>
          </b-form-group>

          <b-form-group
            id="field-min-value"
            label-cols-lg="2"
            :label="$t('COMMON.MIN_VALUE')"
            label-for="field-minValue-input"
          >
            <b-form-input id="field-minValue-input" v-model="newRule.minValue">
            </b-form-input>
          </b-form-group>

          <b-form-group
            id="field-max-value"
            label-cols-lg="2"
            :label="$t('COMMON.MAX_VALUE')"
            label-for="field-maxValue-input"
          >
            <b-form-input id="field-maxValue-input" v-model="newRule.maxValue">
            </b-form-input>
          </b-form-group>
        </template>
      </b-card-body>
    </b-modal>

    <b-modal
      id="modal-change-tariff"
      size="lg"
      ok-only
      @ok="changeTariffModalOkPressed"
      :ok-title="$t('COMMON.CHANGE')"
      @close="changeTariffModalCancelPressed"
      no-close-on-backdrop
    >
      <b-form-group
        id="field-tariff-2"
        label-cols-lg="2"
        :label="$t('COMMON.TYPE')"
        label-for="field-tariff-2"
      >
        <b-form-select
          :value="dto.tariffId"
          :options="tariffSelectOptions"
          @change="onTariffSelected"
        >
          <template v-slot:first>
            <b-form-select-option disabled :value="dto.tariffId">{{
              $t("TEAMS.SELECT_TARIFF")
            }}</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <template v-if="newTariff != null">
        <p>
          {{ $t("PRODUCTS.PRICE") }}: {{ newTariff.priceAsFractional }}
          {{ newTariff.currencyName }}
        </p>
        <p>
          {{ $t("TARIFFS.MAX_USER_COUNT") }}:
          {{ newTariff.maxUserCount != null ? newTariff.maxUserCount : "∞" }}
        </p>
        <p>
          {{ $t("TARIFFS.MAX_DEVICE_COUNT") }}:
          {{
            newTariff.maxDeviceCount != null ? newTariff.maxDeviceCount : "∞"
          }}
        </p>
        <p>
          {{ $t("TARIFFS.MAX_API_REQUEST_COUNT") }}:
          {{
            newTariff.maxRequestCount != null ? newTariff.maxRequestCount : "∞"
          }}
        </p>
        <p>
          {{ $t("TARIFFS.MAX_MANAGED_TEAM_COUNT") }}:
          {{
            newTariff.maxManagedTeamCount != null
              ? newTariff.maxManagedTeamCount
              : "∞"
          }}
        </p>
        <p>
          {{ $t("TARIFFS.ALLOW_LOGO_CUSTOMIZATION") }}:
          {{ newTariff.allowLogoCustomization ? "+" : "-" }}
        </p>
        <p>
          {{ $t("TARIFFS.ALLOW_IP_FILTRATING") }}:
          {{ newTariff.allowIpFiltrating ? "+" : "-" }}
        </p>
        <p>
          {{ $t("TARIFFS.ALLOW_REQUEST_LOGS") }}:
          {{ newTariff.allowRequestLogs ? "+" : "-" }}
        </p>
      </template>
    </b-modal>

    <b-modal
      id="modal-change-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      :cancel-title="$t('COMMON.CANCEL')"
      @ok="onChangeTariff"
      @cancel="onCancelChangeTariff"
      @close="onCancelChangeTariff"
      no-close-on-backdrop
    >
      <p class="my-4">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_CHANGE", {
            name: newTariff ? newTariff.text : "",
          })
        }}
      </p>
    </b-modal>

    <order-documents-modal
      :order.sync="selectedOrder"
      :country.sync="dto.country"
    >
    </order-documents-modal>

    <upload-order-documents-modal :order.sync="selectedOrder">
    </upload-order-documents-modal>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";
import Common from "../../common";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Application from "../../application";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import MultiSuggestion from "../component/MultiSuggestion";
import myUpload from "vue-image-crop-upload";
import { SET_LOGO } from "../../core/services/store/auth.module";
import OrderDocumentsModal from "../component/OrderDocumentsModal";
import i18nService from "../../core/services/i18n.service";
import { required, requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import UploadOrderDocumentsModal from "../component/UploadOrderDocumentsModal";

export default {
  name: "team",
  mixins: [SuggestionProcessorMixin, validationMixin],
  components: {
    //Templates
    "my-upload": myUpload,
    MultiSuggestion,
    OrderDocumentsModal,
    UploadOrderDocumentsModal,
  },
  data() {
    return {
      save: {
        resource: "/api/team/save",
        requestType: "POST",
        requestParams: {},
      },

      logo: {
        show: false,
        params: {
          name: "image",
        },
        headers: {
          Token: localStorage.id_token,
        },
        imgDataUrl: null,
        logoChanged: false,
      },

      invitationRequest: {
        resource: "/api/team/invite",
        requestType: "POST",
        requestParams: {},
      },
      managed: false, // управляемая команда (оплачивается с аккаунта текущей команды)
      storedCountryId: null,
      storedTeamTypeId: null,
      newEmail: "",
      mailingList: [],
      dto: {
        id: null,
        mailingList: [],
        name: null,
        teamTypeId: null,
        ownerName: null,
        ownerId: null,
        countryId: null,
        tariffName: null,
        tariffId: null,
        taxNumber: null,
        phone: null,
        email: null,
        address: null,
        web: null,
        logo: null,
        companyName: null,
        directorName: null,
        kpp: null,
        ogrn: null,
        checkingAccount: null,
        bankName: null,
        correspondentAccount: null,
        bik: null,
        parentTeamId: null,
        country: null,
        members: [],
        notificationRules: [],
      },
      newMember: {
        id: null,
        permissionId: null,
        userId: null,
        userName: null,
        permissionName: null,
        email: null,
        teamId: null,
      },
      newRule: {
        id: null,
        maxValue: null,
        minValue: null,
        notificationRuleTypeId: null,
        notificationRuleTypeName: null,
        devices: [],
        parameters: [],
        userId: null,
        userName: null,
      },

      memberFields: [
        {
          label: this.$t("COMMON.CREATED"),
          key: "created",
          formatter: Common.idObjectFormatter,
        },
        { label: this.$t("COMMON.USER"), key: "userName" },
        {
          label: this.$t("TEAMS.PERMISSION"),
          formatter: this.perFormatter,
          key: "permissionId",
        },
      ],

      notificationRuleFields: [
        {
          label: this.$t("COMMON.CREATED"),
          key: "created",
          formatter: Common.idObjectFormatter,
        },
        {
          label: this.$t("COMMON.TYPE"),
          formatter: this.ruleFormatter,
          key: "notificationRuleTypeId",
        },
        {
          label: this.$t("MENU.DEVICES"),
          key: "devices",
          formatter: Common.formatListToString,
        },
      ],

      orderFields: [
        { label: this.$t("MENU.PRODUCTS"), key: "productsAsString" },
        {
          label: this.$t("TEAMS.OWNERSHIP_TYPE"),
          formatter: this.ownershipFormatter,
          key: "ownershipTypeId",
        },
        { label: this.$t("TEAMS.TOTAL"), key: "totalAmountAsFractional" },
        { label: this.$t("ORDERS.PAID"), key: "paidAsFractional" },
        { label: this.$t("ACCOUNTS.CURRENCY"), key: "targetCurrencyName" },
        {
          label: this.$t("TEAMS.STATE"),
          formatter: this.stateFormatter,
          key: "orderStateId",
        },
        {
          label: this.$t("TEAMS.SUBSCRIPTION_CANCELLED"),
          key: "subscriptionCancelled",
          formatter: Common.booleanFormatter,
        },
      ],

      teamTypes: [],
      teamTypesRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.TeamType",
        requestType: "GET",
        requestParams: {},
      },

      countries: [],
      countriesRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.Country",
        requestType: "GET",
        requestParams: {},
      },

      notificationRuleTypes: [],
      notificationRuleTypesRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.NotificationRuleType",
        requestType: "GET",
        requestParams: {},
      },

      orders: [],
      ordersRequest: {
        resource: "/api/team/" + this.$route.params.id + "/orders",
        requestType: "GET",
        requestParams: {},
      },

      tariffsRequest: {
        resource: "/api/team/" + this.$route.params.id + "/available-tariffs",
        requestType: "GET",
        requestParams: {},
      },

      showOverlay: true,

      selectedUserId: "",
      teamCountryStr: "", // то, что напечатал пользователь в поле выбора страны

      getPermissions: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.Permission",
        requestType: "GET",
        requestParams: {},
      },
      permissions: [],

      getOwnership: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.market.model.OwnershipType",
        requestType: "GET",
        requestParams: {},
      },
      getOrderState: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.market.model.OrderState",
        requestType: "GET",
        requestParams: {},
      },

      selectedTeam: null,
      selectedRule: null,
      selectedOrder: null,

      filterDevices: "",
      selectedDevices: [],

      filterParameters: "",
      selectedParameters: [],

      constants: Application.constants,

      hasManageRights: false,
      tariffSelectOptions: [],
      newTariff: null,

      currentTariffInfo: {
        // информация по текущему тарифу
        priceAsFractional: null,
        currencyName: null,
        maxUserCount: null,
        maxManagedTeamCount: null,
        maxRequestCount: null,
        allowLogoCustomization: false,
        allowIpFiltrating: false,
        allowRequestLogs: false,
      },

      products: {},
      permissionMap: {},
      ownershipMap: {},
      stateMap: {},
      ruleMap: {},

      tempId: 1,
      countryKey: 0,
    };
  },

  validations() {
    let teamCorporate =
      this.dto.teamTypeId == Application.constants.TEAM_TYPES.CORPORATE;
    let rurAvailable =
      teamCorporate &&
      this.dto.country &&
      this.dto.country.rurAvailable === true;

    let dto = {
      name: { required },
      companyName: {
        required: requiredIf(function (form) {
          return !!form && teamCorporate;
        }),
      },
      taxNumber: {
        required: requiredIf(function (form) {
          return !!form && teamCorporate;
        }),
      },
      phone: {
        required: requiredIf(function (form) {
          return !!form && teamCorporate;
        }),
      },
      email: {
        required: requiredIf(function (form) {
          return !!form && teamCorporate;
        }),
      },
      address: {
        required: requiredIf(function (form) {
          return !!form && teamCorporate;
        }),
      },
      directorName: {
        required: requiredIf(function (form) {
          return !!form && teamCorporate;
        }),
      },

      kpp: {
        required: requiredIf(function (form) {
          return !!form && rurAvailable;
        }),
      },
      ogrn: {
        required: requiredIf(function (form) {
          return !!form && rurAvailable;
        }),
      },
      checkingAccount: {
        required: requiredIf(function (form) {
          return !!form && rurAvailable;
        }),
      },
      bankName: {
        required: requiredIf(function (form) {
          return !!form && rurAvailable;
        }),
      },
      correspondentAccount: {
        required: requiredIf(function (form) {
          return !!form && rurAvailable;
        }),
      },
      bik: {
        required: requiredIf(function (form) {
          return !!form && rurAvailable;
        }),
      },
    };

    return {
      dto: dto,
    };
  },

  mounted() {
    let title = !this.$route.params.id
      ? this.$t("TEAMS.CREATE_NEW_TEAM")
      : this.$t("TEAMS.EDIT_TEAM");
    let bc = [];
    if (this.user.grants.includes("SYSTEM:ADMIN")) {
      bc.push({ title: this.$t("MENU.TEAMS"), route: "/teams" });
    }
    bc.push({ title: title });

    this.$store.dispatch(SET_BREADCRUMB, bc);
  },

  created: function () {
    let total = 0;
    let that = this;
    let onLoad = function () {
      total++;
      if (total === 2) that.showOverlay = false;
    };

    Promise.all([
      this.loadTeamTypes(),
      this.loadPermissions(),
      this.loadOwnership(),
      this.loadOrderStates(),
      this.loadNotificationRuleTypes(),
    ]).then(onLoad);

    let id = this.$route.params.id;
    if (id) {
      this.load(this.$route.params.id).then(onLoad);
    } else {
      onLoad();
    }
  },
  beforeRouteUpdate: async function (to, from, next) {
    this.showOverlay = true;
    if (to.params.id) {
      await this.load(to.params.id);
    } else {
      this.dto = {
        id: null,
        name: null,
        teamTypeId: null,
        ownerName: null,
        ownerId: null,
        countryId: null,
        tariffName: null,
        tariffId: null,
        taxNumber: null,
        phone: null,
        email: null,
        address: null,
        web: null,
        logo: null,
        companyName: null,
        directorName: null,
        kpp: null,
        ogrn: null,
        checkingAccount: null,
        bankName: null,
        correspondentAccount: null,
        bik: null,
        parentTeamId: null,
        country: null,
        members: [],
        notificationRules: [],
      };
      this.orders = [];
      this.permissions = [];
      this.tariffSelectOptions = [];
      this.managed = false;
      this.storedCountryId = null;
      this.storedTeamTypeId = null;
      this.logo = {
        show: false,
        params: {
          name: "image",
        },
        headers: {
          Token: localStorage.id_token,
        },
        imgDataUrl: null,
        logoChanged: false,
      };
      this.teamCountryStr = null;
    }
    this.showOverlay = false;

    next();
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      myTeams: (state) => state.team.myTeams,
    }),
    readOnly() {
      return (
        !this.user.grants.includes("SYSTEM:ADMIN") &&
        this.dto.id != null &&
        !this.hasManageRights
      );
    },
    getLogoUrl: function () {
      return this.logo.imgDataUrl || Application.constants.DEFAULT_LOGO;
    },
  },

  methods: {
    addEmail() {
      if (this.isValidEmail(this.newEmail)) {
        this.mailingList.push(this.newEmail);
        this.newEmail = "";
      } else {
        this.$bvToast.toast("Invalid email address", {
          title: "Warning",
          variant: "warning",
          autoHideDelay: 3000,
        });
      }
    },
    removeEmail(index) {
      this.mailingList.splice(index, 1);
    },
    isValidEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
      return re.test(String(email).toLowerCase());
    },
    validateDtoState(name) {
      const { $dirty, $error } = this.$v.dto[name];
      return $dirty ? !$error : null;
    },

    load: async function (id) {
      return await this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/team/" + id,
          requestType: "GET",
        })
        .then((response) => {
          if (id == "greeting") {
            id = ApiService.teamData.value;
            if (response.teamTypeId != null && response.countryId != null) {
              this.$router.push({
                name: "monitoring",
              });
              return;
            }
          }

          this.dto = response;
          this.mailingList = response.mailingList
          console.log(response.mailingList)

          this.teamCountryStr = this.dto.countryName;
          this.logo.imgDataUrl =
            this.dto.logo != null
              ? this.dto.logo
              : process.env.BASE_URL + "media/logos/natlogger.png";
          this.storedCountryId = this.dto.countryId;
          this.storedTeamTypeId = this.dto.teamTypeId;

          for (let i = 0; i < this.myTeams.length; i++) {
            let t = this.myTeams[i];
            if (
              t.id == id &&
              t.permissionId == Application.constants.PERMISSIONS.MANAGE
            ) {
              this.hasManageRights = true;
              break;
            }
          }

          this.loadOrders(id);
          this.loadTariffs(id);
        })
        .catch(this.onError);
    },

    loadPermissions: function () {
      return this.$store
        .dispatch(API_REQUEST, this.getPermissions)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.permissionMap[response[i].id] = response[i];
          }
          for (const value of response) {
            this.permissions.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    loadOwnership: function () {
      return this.$store
        .dispatch(API_REQUEST, this.getOwnership)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.ownershipMap[response[i].id] = response[i];
          }
        })
        .catch(this.onError);
    },

    loadOrderStates: function () {
      return this.$store
        .dispatch(API_REQUEST, this.getOrderState)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.stateMap[response[i].id] = response[i];
          }
        })
        .catch(this.onError);
    },

    addNewMember: function (bvModalEvt) {
      if (!this.newMember.email && !this.selectedUserId && !this.newMember.id) {
        this.$bvToast.toast(this.$t("TEAMS.USER_NOT_SET"), {
          title: this.$t("COMMON.WARNING"),
          variant: "warning",
          autoHideDelay: 5000,
        });
        bvModalEvt.preventDefault();
        return;
      }

      if (!this.newMember.permissionId) {
        this.$bvToast.toast(this.$t("TEAMS.PERMISSION_NOT_SET"), {
          title: this.$t("COMMON.WARNING"),
          variant: "warning",
          autoHideDelay: 5000,
        });
        bvModalEvt.preventDefault();
        return;
      }

      for (let i = 0; i < this.permissions.length; i++) {
        if (this.permissions[i].value == this.newMember.permissionId) {
          this.newMember.permissionName = this.permissions[i].text;
          break;
        }
      }

      if (this.newMember.id != null) {
        // edit member
        for (let i = 0; i < this.dto.members.length; i++) {
          if (this.newMember.id == this.dto.members[i].id) {
            this.dto.members[i] = this.newMember;
            break;
          }
        }
      } else {
        // add member
        if (!this.newMember.email) {
          this.newMember.userId = this.selectedUserId;
          this.dto.members.push(this.newMember);
        } else {
          //invitation
          this.newMember.teamId = this.dto.id;
          this.sendInvitation();
        }
      }

      this.newMember = {
        id: null,
        permissionId: null,
        permissionName: null,
        userId: null,
        userName: null,
        email: null,
        teamId: null,
      };
      this.selectedUserId = "";
    },

    addNewRule: function () {
      let typeName;
      for (const type of this.notificationRuleTypes) {
        if (type.value == this.newRule.notificationRuleTypeId)
          typeName = type.text;
      }
      this.newRule.devices = [];
      this.newRule.parameters = [];

      this.newRule.userId = this.selectedUserId;
      if (this.newRule.userId == "" || this.newRule.userId == null) {
        this.newRule.userId = this.user.id;
        this.newRule.userName = this.user.formattedUserName;
      }
      this.newRule.notificationRuleTypeName = typeName;
      for (const device of this.selectedDevices) {
        this.newRule.devices.push(device);
      }
      for (const parameter of this.selectedParameters) {
        this.newRule.parameters.push(parameter);
      }

      if (this.newRule.id) {
        // edit rule
        for (let i = 0; i < this.dto.notificationRules.length; i++) {
          if (this.newRule.id == this.dto.notificationRules[i].id) {
            this.dto.notificationRules[i] = this.newRule;
          }
        }
      } else {
        // add rule
        this.newRule.id = this.tempId;
        this.tempId++;
        this.dto.notificationRules.push(this.newRule);
      }

      this.newRule = {
        id: null,
        maxValue: null,
        minValue: null,
        notificationRuleTypeId: null,
        notificationRuleTypeName: null,
        devices: [],
        parameters: [],
        userName: null,
        userId: null,
      };

      this.selectedDevices.splice(0, this.selectedDevices.length);
      this.selectedParameters.splice(0, this.selectedParameters.length);

      this.selectedUserId = "";
    },

    cancelAddTeamMember: function () {
      this.newMember = { permissionId: null, userId: null, userName: null };
      this.selectedUserId = "";
    },

    cancelRule: function () {
      this.newRule = {
        id: null,
        maxValue: null,
        minValue: null,
        notificationRuleTypeId: null,
        notificationRuleTypeName: null,
        devices: [],
        parameters: [],
        userName: null,
        userId: null,
      };
      this.selectedUserId = "";
      this.selectedDevices.splice(0, this.selectedDevices.length);
      this.selectedParameters.splice(0, this.selectedParameters.length);
    },

    openNewMemberModal: function () {
      this.$bvModal.show("modal-add-member");
    },

    deleteMember: function (row) {
      this.dto.members.splice(row.index, 1);
    },

    loadTeamTypes: function () {
      return this.$store
        .dispatch(API_REQUEST, this.teamTypesRequest)
        .then((response) => {
          for (const value of response) {
            this.teamTypes.push({ value: value.id, text: value.nameLocalized });
          }
        })
        .catch(this.onError);
    },

    loadCountries: function () {
      this.$store
        .dispatch(API_REQUEST, this.countriesRequest)
        .then((response) => {
          for (const value of response) {
            this.countries.push({ value: value.id, text: value.nameLocalized });
          }
        })
        .catch(this.onError);
    },

    loadNotificationRuleTypes: function () {
      return this.$store
        .dispatch(API_REQUEST, this.notificationRuleTypesRequest)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.ruleMap[response[i].id] = response[i];
          }
          for (const value of response) {
            this.notificationRuleTypes.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    loadOrders: function (id) {
      if (id == null || this.readOnly) {
        return;
      }
      this.ordersRequest.resource = "/api/team/" + id + "/orders";
      this.ordersRequest.requestParams = {
        sortField: "el.created",
        sortDir: "desc",
        count: 100,
      };

      this.$store
        .dispatch(API_REQUEST, this.ordersRequest)
        .then((response) => {
          let resp = response.data;
          this.orders.splice(0, this.orders.length);

          for (const value of resp) {
            let proList = "";
            for (let i = 0; i < value.products.length; i++) {
              proList += value.products[i].nameLocalized;
              if (value.products[i].defermentExpiration) {
                proList +=
                  " (" +
                  this.$t("TEAMS.DEF_EXP") +
                  ": " +
                  Application.dateFormatter(
                    value.products[i].defermentExpiration,
                    "date"
                  ) +
                  ")";
              }
              if (i + 1 < value.products.length) {
                proList += ", ";
              }
            }
            value.productsAsString = proList;

            this.orders.push(value);
          }
        })
        .catch(this.onError);
    },

    loadTariffs: function (id) {
      if (id == null) {
        return;
      }

      this.tariffsRequest.resource = "/api/team/" + id + "/available-tariffs";

      this.$store
        .dispatch(API_REQUEST, this.tariffsRequest)
        .then((response) => {
          this.tariffSelectOptions = [];
          for (const tariff of response) {
            let options = {
              text: tariff.nameLocalized,
              value: tariff.products[0].id,
            };
            for (const prod of tariff.products) {
              // список
              this.products[prod.id] = {
                id: tariff.id,
                text: prod.nameLocalized,
                productId: prod.id,

                priceAsFractional: prod.priceAsFractional,
                currencyName: prod.currencyName,
                maxUserCount: tariff.maxUserCount,
                maxDeviceCount: tariff.maxDeviceCount,
                maxRequestCount: tariff.maxRequestCount,
                maxManagedTeamCount: tariff.maxManagedTeamCount,
                allowIpFiltrating: tariff.allowIpFiltrating,
                allowLogoCustomization: tariff.allowLogoCustomization,
                allowRequestLogs: tariff.allowRequestLogs,
              };
            }
            this.tariffSelectOptions.push(options);

            if (this.dto.tariffId == tariff.id) {
              this.currentTariffInfo.allowLogoCustomization =
                tariff.allowLogoCustomization;
              this.currentTariffInfo.allowIpFiltrating =
                tariff.allowIpFiltrating;
              this.currentTariffInfo.allowRequestLogs = tariff.allowRequestLogs;
            }

            this.teamCountryStr = this.dto.countryName;
          }
        })
        .catch(this.onError);
    },

    onUserSelected: function (value) {
      this.selectedUserId = value.id;
    },

    onTeamCountrySelected: function (value) {
      this.dto.countryId = value.id;
      this.dto.country = {
        id: value.id,
        rurAvailable: value.tags.rurAvailable === "true",
        eurAvailable: value.tags.eurAvailable === "true",
        usdAvailable: value.tags.usdAvailable === "true",
      };
      this.countryKey++;
    },

    async onSaveClicked() {
      this.$v.dto.$touch();
      if (this.$v.dto.$anyError) {
        this.$bvToast.toast(this.$t("TEAMS.PLEASE_PROVIDE_CORRECT_DATA"), {
          title: this.$t("COMMON.WARNING"),
          variant: "warning",
          autoHideDelay: 5000,
        });
        return;
      }
      this.showOverlay = true;

      //
      // LOAD LOGO ->
      if (
        this.logo.logoChanged &&
        this.logo.imgDataUrl != null &&
        this.currentTariffInfo.allowLogoCustomization
      ) {
        const logoRequest = {
          resource: "api/resource",
          requestType: "POST",
          requestParams: {},
        };
        const fmData = new FormData();
        fmData.append("image", this.data2blob(this.logo.imgDataUrl, "png"));
        logoRequest.dto = fmData;
        let result = await this.$store
          .dispatch(API_REQUEST, logoRequest)
          .then((response) => {
            this.dto.logo = response;
            return true;
          })
          .catch(this.onError);

        if (!result) {
          this.showOverlay = false;
          return;
        }
      } else if (this.logo.imgDataUrl == null) {
        this.dto.logo = null;
      }
      this.logo.logoChanged = false;
      // <- LOAD LOGO

      if (!this.currentTariffInfo.allowIpFiltrating) {
        this.dto.allowedIpAddresses = null;
      }

      if (!this.currentTariffInfo.allowLogoCustomization) {
        this.dto.logo = null;
      }

      let isNew = false;
      if (this.dto.id == null) {
        isNew = true;
        this.dto.parentTeamId = this.managed ? ApiService.teamData.value : null;
      }
      this.dto.mailingList = this.mailingList;
      const request = this.save;
      request.dto = this.dto;
      this.dto.notificationRules.forEach((rule) => {
        if (rule.id.toString().length < 10) rule.id = null;
      });

      let result = await this.$store
        .dispatch(API_REQUEST, request)
        .catch(this.onError);

      if (!result) return;

      this.dto.id = result.id;

      if (isNew) {
        // if new - full teamDTO reload
        this.hasManageRights = true; // we have manage rights because we're just created the team
        await this.load(this.dto.id);
      } else {
        this.storedCountryId = this.dto.countryId; // store country so user cannot edit it anymore
        this.storedTeamTypeId = this.dto.teamTypeId; // same for team type
        this.loadTariffs(this.dto.id);
        if (this.dto.id === ApiService.teamData.value) {
          if (this.dto.logo) {
            localStorage.setItem("teamLogo", this.dto.logo);
            this.$store.commit(SET_LOGO, this.dto.logo);
          } else {
            localStorage.removeItem("teamLogo");
            this.$store.commit(SET_LOGO, Application.constants.DEFAULT_LOGO);
          }
        }
      }

      this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
        title: this.$t("COMMON.SUCCESS"),
        variant: "success",
        autoHideDelay: 5000,
      });

      this.showOverlay = false;
    },

    sendInvitation: function () {
      let request = this.invitationRequest;
      request.dto = this.newMember;

      this.$store
        .dispatch(API_REQUEST, request)
        .then(() => {
          this.$bvToast.toast(this.$t("TEAMS.INVITATION_SENT_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    onTeamRowSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedTeam = items[0];
        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedTeam = null;
      }
    },

    onRuleRowSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedRule = items[0];
        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedRule = null;
      }
    },

    onOrderRowSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedOrder = items[0];
        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedOrder = null;
      }
    },

    tabChanged: async function (newTabIndex) {
      let that = this;
      if (newTabIndex == 1 && !this.readOnly) {
        // parameter mapping
        this.$store.dispatch(SET_ACTIONS, [
          {
            label: this.$t("ACTIONS.ADD"),
            action: this.onCreateNewTeamAction,
            icon: "fas fa-plus",
          },
          {
            label: this.$t("ACTIONS.EDIT"),
            action: this.onEditTeamAction,
            disabled: function () {
              return that.selectedTeam === null || that.showOverlay;
            },
            icon: "fas fa-edit",
          },
          {
            label: this.$t("ACTIONS.DELETE"),
            action: this.onDeleteTeamAction,
            disabled: function () {
              return that.selectedTeam === null || that.showOverlay;
            },
            icon: "fas fa-trash-alt",
          },
        ]);
      } else if (newTabIndex == 2 || (newTabIndex == 1 && this.readOnly)) {
        this.$store.dispatch(SET_ACTIONS, [
          {
            label: this.$t("ACTIONS.ADD"),
            action: this.onCreateNewRuleAction,
            icon: "fas fa-plus",
          },
          {
            label: this.$t("ACTIONS.EDIT"),
            action: this.onEditRuleAction,
            disabled: function () {
              return that.selectedRule === null || that.showOverlay;
            },
            icon: "fas fa-edit",
          },
          {
            label: this.$t("ACTIONS.DELETE"),
            action: this.onDeleteRuleAction,
            disabled: function () {
              return that.selectedRule === null || that.showOverlay;
            },
            icon: "fas fa-trash-alt",
          },
        ]);
      } else if (newTabIndex == 3 || (newTabIndex == 2 && this.readOnly)) {
        this.$store.dispatch(SET_ACTIONS, [
          {
            label: this.$t("ACTIONS.EXECUTE"),
            action: this.onExecuteOrderAction,
            disabled: () => {
              if (that.selectedOrder === null || that.showOverlay) {
                return true;
              }

              return !(
                that.selectedOrder.orderStateId ===
                  Application.constants.ORDER_STATES.DRAFT ||
                that.selectedOrder.orderStateId ===
                  Application.constants.ORDER_STATES.PENDING
              );
            },
            icon: "fas fa-edit",
          },
          {
            label: this.$t("TEAMS.VIEW_ORDER_DOCS"),
            action: this.openOrderDocumentsModal,
            disabled: () => {
              return (
                !that.selectedOrder ||
                that.dto.teamTypeId !=
                  Application.constants.TEAM_TYPES.CORPORATE ||
                that.dto.countryId == null ||
                that.selectedOrder.orderStateId ==
                  Application.constants.ORDER_STATES.PAID
              );
            },
            icon: "fas fa-file",
          },
          {
            label: this.$t("TEAMS.UPLOAD_ORDER_DOCS"),
            action: this.openUploadOrderDocumentsModal,
            disabled: () => {
              return (
                !that.selectedOrder ||
                that.dto.teamTypeId !=
                  Application.constants.TEAM_TYPES.CORPORATE ||
                that.dto.countryId == null ||
                that.selectedOrder.orderStateId ==
                  Application.constants.ORDER_STATES.PAID
              );
            },
            icon: "fas fa-file",
          },
          {
            label: this.$t("TEAMS.CANCEL_SUBSCRIPTION"),
            action: this.onCancelSubscriptionAction,
            disabled: () => {
              if (that.selectedOrder === null || that.showOverlay) {
                return true;
              }

              return (
                (that.selectedOrder.orderStateId !=
                  Application.constants.ORDER_STATES.PAID &&
                  that.selectedOrder.orderStateId !=
                    Application.constants.ORDER_STATES.PENDING) ||
                that.selectedOrder.ownershipTypeId !=
                  Application.constants.OWNERSHIP_TYPES.SUBSCRIPTION ||
                that.selectedOrder.subscriptionCancelled == true
              );
            },
            icon: "fas fa-cut",
          },
        ]);
      } else {
        this.$store.dispatch(SET_ACTIONS, null);
        this.selectedTeam = null;
        this.selectedRule = null;
      }
    },

    onCreateNewTeamAction: function () {
      this.$bvModal.show("modal-add-member");
    },

    onEditTeamAction: function () {
      this.newMember = this.selectedTeam;
      this.$bvModal.show("modal-add-member");
    },

    onDeleteTeamAction: function () {
      for (let i = 0; i < this.dto.members.length; i++) {
        if (this.dto.members[i].id == this.selectedTeam.id) {
          this.dto.members.splice(i, 1);
        }
      }
    },

    onCreateNewRuleAction: function () {
      this.selectedDevices = [];
      this.selectedParameters = [];
      this.newRule = {
        id: null,
        maxValue: null,
        minValue: null,
        notificationRuleTypeId: null,
        notificationRuleTypeName: null,
        devices: [],
        parameters: [],
        userId: null,
        userName: null,
      };
      this.$bvModal.show("modal-add-rule");
    },

    onEditRuleAction: function () {
      this.newRule = this.selectedRule;
      this.selectedDevices = [];
      this.selectedParameters = [];

      for (const device of this.newRule.devices) {
        this.selectedDevices.push(device);
      }
      for (const parameter of this.newRule.parameters) {
        this.selectedParameters.push(parameter);
      }
      this.$bvModal.show("modal-add-rule");
    },

    onDeleteRuleAction: function () {
      for (let i = 0; i < this.dto.notificationRules.length; i++) {
        if (this.dto.notificationRules[i].id == this.selectedRule.id) {
          this.dto.notificationRules.splice(i, 1);
        }
      }
    },

    openOrderDocumentsModal: function () {
      this.$bvModal.show("modal-order-documents");
    },

    openUploadOrderDocumentsModal: function () {
      this.$bvModal.show("modal-upload-order-documents");
    },

    onExecuteOrderAction: function () {
      if (this.storedCountryId == null) {
        this.$bvToast.toast(this.$t("TEAMS.SELECT_TEAM_COUNTRY_FIRST"), {
          title: this.$t("COMMON.WARNING"),
          variant: "warning",
          autoHideDelay: 5000,
        });
        return;
      }

      this.$router.push({
        name: "checkout",
        params: { id: this.selectedOrder.id },
      });
    },

    onCancelSubscriptionAction: function () {
      this.$bvModal.show("modal-cancel-subscription-confirmation");
    },

    onCancelSubscription: function () {
      let that = this;
      const r = {
        resource:
          "/platform/api/order/" +
          this.selectedOrder.id +
          "/cancel-subscription",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, r)
        .then(() => {
          this.$bvToast.toast(
            this.$t("TEAMS.SUBSCRIPTION_CANCELLED_SUCCESSFULLY"),
            {
              title: this.$t("COMMON.SUCCESS"),
              variant: "success",
              autoHideDelay: 5000,
            }
          );

          // update table
          this.loadOrders(that.dto.id);
        })
        .catch(this.onError);
    },

    onDeviceSelected(data) {
      //@ts-ignore
      this.selectedDevices.push(data);
    },

    removeDevice(index, data) {
      this.selectedDevices.splice(index, 1);
      this.newRule.devices.splice(index, 1);
    },

    onParameterSelected(data) {
      //@ts-ignore
      this.selectedParameters.push(data);
    },

    removeParameter(index, data) {
      this.selectedParameters.splice(index, 1);
      this.newRule.parameters.splice(index, 1);
    },

    onShowTariffModal: function () {
      this.$bvModal.show("modal-change-tariff");
    },

    changeTariffModalOkPressed: function () {
      this.$bvModal.show("modal-change-confirmation");
    },

    changeTariffModalCancelPressed: function () {
      this.newTariff = null;
    },

    onTariffSelected: function (value) {
      this.newTariff = this.products[value];
    },

    onChangeTariff: function (event) {
      event.preventDefault();
      const request = {
        resource: "/api/team/" + this.dto.id + "/change-tariff",
        requestType: "POST",
        requestParams: {},
      };
      request.dto = { productId: this.newTariff.productId };
      this.$store
        .dispatch(API_REQUEST, request)
        .then(() => {
          this.$bvModal.hide("modal-change-confirmation");
          this.$bvToast.toast(this.$t("TEAMS.TARIFF_CHANGED_PLEASE"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 8000,
          });
          this.dto.tariffId = this.newTariff.id;
          this.dto.tariffName = this.newTariff.text;
          this.loadOrders(this.dto.id);
          this.newTariff = null;
        })
        .catch(this.onError);
    },

    onCancelChangeTariff: function () {
      this.newTariff = null;
    },

    getCurrentLang() {
      return i18nService.getActiveLanguage();
    },

    changeLogo() {
      this.logo.show = !this.logo.show;
    },
    undoLogo() {
      this.logo.imgDataUrl =
        this.dto.logo != null
          ? this.dto.logo
          : process.env.BASE_URL + "media/logos/natlogger.png";
      this.logo.logoChanged = false;
    },
    deleteLogo() {
      this.logo.imgDataUrl = null;
      this.logo.logoChanged = true;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl) {
      this.logo.imgDataUrl = imgDataUrl;
      this.logo.logoChanged = true;
    },
    data2blob(data, format) {
      let mimes = {
        jpg: "image/jpeg",
        png: "image/png",
        gif: "image/gif",
        svg: "image/svg+xml",
        psd: "image/photoshop",
      };
      let mime = mimes[format];
      data = data.split(",")[1];
      data = window.atob(data);
      let ia = new Uint8Array(data.length);
      for (let i = 0; i < data.length; i++) {
        ia[i] = data.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mime,
      });
    },

    suggestFormater: function (s) {
      let str = s.name;
      if (s.description) {
        str = str + "\n(" + s.description + ")";
      }
      return str;
    },

    perFormatter: function (value) {
      if (value && value in this.permissionMap)
        return this.permissionMap[value].nameLocalized;

      return "";
    },
    ownershipFormatter: function (value) {
      if (value && value in this.ownershipMap)
        return this.ownershipMap[value].nameLocalized;

      return "";
    },
    stateFormatter: function (value) {
      if (value && value in this.stateMap)
        return this.stateMap[value].nameLocalized;

      return "";
    },
    ruleFormatter: function (value) {
      if (value && value in this.ruleMap)
        return this.ruleMap[value].nameLocalized;

      return "";
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
.vue-image-crop-upload .vicp-wrap {
  height: 85%;
  width: 85%;
  max-width: 600px;
  max-height: 460px;
}
.team-logo {
  max-width: 100px;
  max-height: 100px;
  padding: 0;
  border: 0;
}
.img-button-group {
  margin-left: 10px;
  text-align: left;
}
.img-button-change {
  text-align: left;
  color: #6d7392;
}
.img-button-undo {
  text-align: left;
  color: #ffa500;
}
.img-button-delete {
  text-align: left;
  color: #f04a4f;
}
.tags-input-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  border: 1px solid #ced4da;
  padding: 5px;
  border-radius: 4px;
}

.tag {
  background-color: #fff;
  color: #000;
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid #ced4da;
  display: flex;
  align-items: center;
}

.remove-tag {
  margin-left: 8px;
  cursor: pointer;
  color: #000;
}
</style>
